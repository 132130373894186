import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { withRouter } from "react-router";
import { Mutation } from "react-apollo";
import ExpiringAlert from "../ExpiringAlert";
import i18n from "../../views/Pages/Login/i18n";

class EditDeleteButton extends Component {
  constructor(arg) {
    super(arg);
    this.state = { dropdownOpen: false };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.onEdit) {
      this.props.onEdit(this.props.id);
    } else {
      this.props.history.push(this.props.editUrl);
    }
  };

  render() {
    return (
      <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle>...</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={this.handleEdit}>Edit</DropdownItem>
          <Mutation
            mutation={this.props.deleteMutation}
            variables={{ id: this.props.id }}
            refetchQueries={this.props.deleteRefetchQueries}
          >
            {(deleteSchema) => (
              <DropdownItem
                onClick={() => {
                  if (confirm("Are you sure?")) {
                    deleteSchema()
                      .then(({ data }) => {
                        console.log("got data", data);
                        ReactDOM.render(
                          <ExpiringAlert
                            color='success'
                            message={i18n.t("schema.deleted")}
                          />,
                          document.getElementById("alert")
                        );
                      })
                      .catch((error) => {
                        ReactDOM.render(
                          <ExpiringAlert
                            color='danger'
                            message={error.message}
                          />,
                          document.getElementById("alert")
                        );
                        console.log(
                          "there was an error sending the query",
                          error
                        );
                      });
                  }
                }}
              >
                Delete
              </DropdownItem>
            )}
          </Mutation>
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}

export default withRouter(EditDeleteButton);
