import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { withApollo, Mutation } from "react-apollo";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Form from "react-jsonschema-form";
import schema from "../../schemas/documentLine.schema.json";
import uischema from "../../schemas/documentLine.uischema";
import { findDocumentRow, updateDocumentLine } from "../../queries/Queries";
import ExpiringAlert from "../../components/ExpiringAlert";

const EditDocumentLineModal = ({
  client,
  isOpen,
  onClose,
  documentType,
  documentLineId,
  refetchDocumentDetails,
}) => {
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    const fetchDocumentRow = async () => {
      if (documentLineId && isOpen) {
        try {
          const result = await client.query({
            query: findDocumentRow,
            variables: { id: documentLineId },
            fetchPolicy: "network-only",
          });
          const documentRow = result.data.findDocumentRow;
          if (documentRow) {
            setFormData({
              priority: documentRow.index || 1,
              lineFactoryName: documentRow.lineFactoryName || "",
              productId: (documentRow.product && documentRow.product.id) || "",
              name: documentRow.name || "",
              created: documentRow.created || "",
              createdBy: documentRow.createdBy || "",
              tenant: documentRow.tenant || "",
            });
          }
        } catch (error) {
          console.error("Error fetching document line data", error);
        }
      }
    };

    if (isOpen) {
      fetchDocumentRow();
    }

    return () => {
      setFormData(null);
    };
  }, [documentLineId, isOpen, client]);

  const toggleClose = () => {
    setFormData(null);
    onClose();
  };

  const renderAlert = (message, color) => {
    ReactDOM.render(
      <ExpiringAlert color={color} message={message} />,
      document.getElementById("alert")
    );
  };

  const validate = (formData, errors) => {
    if (formData.priority <= 0) {
      errors.priority.addError("Priority must be greater than 0");
    }
    if (!formData.lineFactoryName) {
      errors.lineFactoryName.addError("Line Factory is required");
    }
    if (!formData.productId) {
      errors.productId.addError("Product is required");
    }
    if (!formData.name) {
      errors.name.addError("Name is required");
    }
    return errors;
  };

  const handleSubmit = (updateDocumentLine) => {
    const variables = {
      id: documentLineId,
      documentId: documentType.id,
      lineFactoryName: formData.lineFactoryName,
      productId: formData.productId,
      name: formData.name,
      priority: formData.priority,
      created: formData.created,
      createdBy: formData.createdBy,
      tenant: formData.tenant,
    };

    updateDocumentLine({ variables })
      .then(() => {
        if (typeof refetchDocumentDetails === "function") {
          refetchDocumentDetails().then(() => {
            toggleClose();
          });
        } else {
          console.error(
            "refetchDocumentDetails is not a function or is undefined"
          );
          toggleClose();
        }
      })
      .catch((error) => {
        console.error("Error updating document line", error);
        renderAlert(error.message, "danger");
      });
  };

  if (formData === null) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleClose}
      size='lg'
      className='document-line-modal'
      backdrop='static'
    >
      <ModalHeader toggle={toggleClose}>Edit Document Line</ModalHeader>
      <ModalBody>
        <Mutation mutation={updateDocumentLine}>
          {(updateDocumentLine) => (
            <Form
              schema={schema}
              uiSchema={uischema}
              formData={formData}
              onChange={({ formData }) => setFormData(formData)}
              onSubmit={() => handleSubmit(updateDocumentLine)}
              validate={validate}
              showErrorList={false}
            >
              <div className='modal-footer'>
                <button type='submit' className='btn btn-primary'>
                  Submit
                </button>
                <button
                  type='button'
                  className='btn btn-secondary'
                  onClick={() => toggleClose()}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Mutation>
      </ModalBody>
    </Modal>
  );
};

export default withApollo(EditDocumentLineModal);
