import React from "react";
import BillingSchemaList from "../../components/BillingSchemaList/BillingSchemaList";

const BillingSchemaListView = () => {
  return (
    <div>
      <BillingSchemaList />
    </div>
  );
};

export default BillingSchemaListView;
