import React from "react";
import { Input } from "reactstrap";

const CustomSelect = ({ value, onChange, options }) => {
  return (
    <Input
      type='select'
      value={value || ""}
      onChange={(event) => onChange(event.target.value)}
    >
      <option value=''> </option>
      {options.enumOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Input>
  );
};

export const createUiSchema = (includeReference) => {
  const uiSchema = {
    "ui:order": includeReference
      ? ["name", "reference", "documentCategory", "executionContext"]
      : ["name", "documentCategory", "executionContext", "reference"],
    documentCategory: {
      "ui:widget": (props) => (
        <CustomSelect
          value={props.value}
          onChange={props.onChange}
          options={{
            enumOptions: [
              { value: "RATING", label: "Rating" },
              { value: "RATING_CONSUMPTION", label: "Rating Consumption" },
              { value: "DNO_RATED_CHARGES", label: "DNO Rated Charges" },
              {
                value: "RATING_NOMINATION_FOR_IMBALANCE_CHARGE",
                label: "Rating Nomination for Imbalance Charge",
              },
              { value: "RATING_NOMINATION", label: "Rating Nomination" },
            ],
          }}
        />
      ),
    },
    executionContext: {
      "ui:widget": (props) => (
        <CustomSelect
          value={props.value}
          onChange={props.onChange}
          options={{
            enumOptions: [
              { value: "SERVICE_INSTANCE", label: "Service Instance" },
              { value: "BILLING_ACCOUNT", label: "Billing Account" },
            ],
          }}
        />
      ),
    },
  };

  if (!includeReference) {
    uiSchema.reference = {
      "ui:widget": "hidden",
    };
  }

  return uiSchema;
};
