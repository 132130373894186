import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const Popup = ({popup, ...props}) => {
    const [toggleModal, setToggleModal] = useState(true);

    useEffect(() => {
        localStorage.getItem(popup.localStorageName)
            ? setToggleModal(false)
            : setToggleModal(true);
    }, []);

    // Do not render the modal if it is not visible
    if (!toggleModal) return null;

    return (
        <Modal isOpen={toggleModal}>
            <ModalHeader>{popup.title}</ModalHeader>
            <ModalBody>
                <div>
                    {popup.content.title &&
                        <p>{popup.content.title}<br></br></p>
                    }
                    <ul style={{ listStyleType: 'none' }}>
                        {popup.content.body.map((item, i) => {
                            // Check if item is object or string
                            if (typeof item === "object") {
                                return (
                                    <Fragment key={i}>
                                        <li>
                                            {item.text} {' '}
                                            <a key={i} href={item.url}>Here</a>
                                        </li>
                                    </Fragment>
                                );
                            } else {
                                return <li key={i}>{item}</li>;
                            }
                        })}
                    </ul>
                    {popup.content.footer &&
                        <p>
                            {popup.content.footer} {' '}
                            <a href="/#/view/contact-us">Contact Us</a>
                        </p>
                    }
                </div>
                <Button
                    onClick={() => {
                        localStorage.setItem(popup.localStorageName, "true");
                        setToggleModal(false);
                    }}
                    color="primary"
                    style={{ float: "right" }}
                >
                    Close
                </Button>
            </ModalBody>
        </Modal>
    );
};

export default Popup;
