import React, { useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Button, CardBody } from "reactstrap";
import InfoCard from "../InfoCard";
import { Query, Mutation } from "react-apollo";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Loading from "cockpit-ui-dataflows/src/components/Loading";
import {
  dateFormatter,
  documentCategoryFormatter,
} from "../../utils/Formatters";
import {
  getBillingSchemaDetailsQuery,
  findAllBillingSchemaParameters,
  removeDocumentTypeFromBillingSchema,
} from "../../queries/Queries";
import AssignDocumentType from "../../components/AssignDocumentType/AssignDocumentType";

const BillingSchemaDetail = ({ history, match }) => {
  const { id } = match.params;
  const { totalItems } = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setCurrentPage(page);
    setPageSize(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  const columnsBillingSchema = useMemo(
    () => [
      { dataField: "name", text: "Name", sort: true },
      {
        dataField: "documentCategory",
        text: "Document Category",
        sort: true,
        formatter: documentCategoryFormatter,
      },
      {
        dataField: "created",
        text: "Created",
        sort: true,
        formatter: (cell, row) => dateFormatter(row.created) || " ",
      },
      {
        dataField: "createdBy",
        text: "Created By",
        sort: true,
        formatter: (cell, row) => row.createdBy || " ",
      },
      {
        dataField: "updated",
        text: "Updated",
        sort: true,
        formatter: (cell, row) => {
          const formattedDate = dateFormatter(row.updated);
          return formattedDate === "Invalid Date" || !formattedDate
            ? " "
            : formattedDate;
        },
      },
      {
        dataField: "updatedBy",
        text: "Updated By",
        sort: true,
        formatter: (cell, row) => <div>{row.updatedBy || " "}</div>,
      },
      {
        dataField: "actions",
        text: "",
        formatter: (cell, row) => (
          <Mutation
            mutation={removeDocumentTypeFromBillingSchema}
            refetchQueries={[
              {
                query: getBillingSchemaDetailsQuery,
                variables: { id },
              },
            ]}
          >
            {(removeDocumentType, { error }) => (
              <div>
                <i
                  className='icon ion-trash-b'
                  style={{
                    fontSize: "30px",
                    color: "green",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const confirmRemoval = window.confirm("Are you sure?");
                    if (confirmRemoval) {
                      removeDocumentType({
                        variables: {
                          billingSchemaId: id,
                          documentTypeId: row.id,
                        },
                      })
                        .then(() => {
                          console.log("Document type removed successfully");
                        })
                        .catch((err) => {
                          console.error(
                            "Error removing document type:",
                            err.message
                          );
                        });
                    }
                  }}
                ></i>
              </div>
            )}
          </Mutation>
        ),
      },
    ],
    [id]
  );

  const formatParameters = (parameters) => (
    <ul className='list-unstyled'>
      {Object.entries(parameters).map(([key, value]) => (
        <li
          key={key}
          className='d-flex justify-content-between align-items-center mb-2'
        >
          {key}
          {value ? (
            <i className='icon ion-checkmark text-success ml-2'></i>
          ) : (
            <i className='icon ion-close text-danger ml-2'></i>
          )}
        </li>
      ))}
    </ul>
  );

  return (
    <Query
      query={getBillingSchemaDetailsQuery}
      variables={{ id }}
      fetchPolicy='cache-and-network'
    >
      {({
        loading: detailsLoading,
        error: detailsError,
        data: detailsData,
      }) => (
        <Query query={findAllBillingSchemaParameters}>
          {({ loading: allLoading, error: allError, data: allData }) => {
            if (detailsLoading || allLoading) return <Loading />;
            if (detailsError) return <p>Error: {detailsError.message}</p>;
            if (allError) return <p>Error: {allError.message}</p>;

            const billingSchema = detailsData.findBillingSchema;
            const allSchemas = allData.BillingSchema.content;

            if (!billingSchema) return <p>No billing schema found</p>;

            const allParameters = {};
            allSchemas.forEach((schema) => {
              Object.keys(schema.parameters).forEach((key) => {
                allParameters[key] = false;
              });
            });

            Object.keys(billingSchema.parameters).forEach((key) => {
              allParameters[key] = billingSchema.parameters[key];
            });

            const documentTypes = billingSchema.docTypes.map((doc) => ({
              ...doc,
              documentCategory: documentCategoryFormatter(doc.documentCategory),
              created: dateFormatter(doc.created),
              updated: doc.updated ? dateFormatter(doc.updated) : " ",
            }));

            return (
              <div className='invoice-details'>
                <Row className='subheader'>
                  <Col className='col-8'>
                    <h1>Billing Details</h1>
                  </Col>
                </Row>
                <div className='px-5 py-4'>
                  <Row>
                    <Col sm='12' className='header'>
                      <Button
                        onClick={() => history.push("/schema-document")}
                        className='btn btn-default'
                      >
                        <i className='icon ion-arrow-left-b'></i>
                        Billing Schema Document: {billingSchema.name}
                      </Button>
                    </Col>
                  </Row>
                  <Row className='my-4'>
                    <Col md='8'>
                      <CardBody className='px-0'>
                        <InfoCard
                          title='Billing Schema Document'
                          info={{
                            name: {
                              key: "Name",
                              value: billingSchema.name,
                            },
                            createdBy: {
                              key: "Created By",
                              value: billingSchema.createdBy || " ",
                            },
                            updatedBy: {
                              key: "Updated By",
                              value: billingSchema.updatedBy || " ",
                            },
                            reference: {
                              key: "Reference",
                              value:
                                billingSchema.billingSchemaReference || " ",
                            },
                            created: {
                              key: "Created",
                              value: dateFormatter(billingSchema.created),
                            },
                            updated: {
                              key: "Updated",
                              value: billingSchema.updated
                                ? dateFormatter(billingSchema.updated)
                                : " ",
                            },
                          }}
                          columns={3}
                        />
                      </CardBody>
                    </Col>
                    <Col md='4'>
                      <CardBody className='px-0'>
                        <InfoCard
                          title='Billing Schema Document Parameters'
                          info={{
                            parameters: {
                              key: "Parameters",
                              value:
                                Object.keys(allParameters).length > 0 ? (
                                  <ul
                                    style={{
                                      listStyleType: "none",
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    {formatParameters(allParameters)}
                                  </ul>
                                ) : (
                                  "No parameters available"
                                ),
                            },
                          }}
                          columns={1}
                        />
                      </CardBody>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='6'>
                      <h4>Billing Document Types</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='8'>
                      <AssignDocumentType schemaId={id} />
                    </Col>
                    <Col sm='4'>
                      <Link
                        to='/document-types/create'
                        className='btn btn-primary pull-right'
                      >
                        <i className='icon ion-plus-round'></i>&nbsp;&nbsp;New
                      </Link>
                    </Col>
                  </Row>
                  <Row className='my-4'>
                    <Col sm='12'>
                      <BootstrapTable
                        classes='table-hover'
                        wrapperClasses='table-responsive'
                        keyField='id'
                        bordered={false}
                        data={documentTypes}
                        columns={columnsBillingSchema}
                        sort={{
                          dataField: sortField,
                          order: sortOrder,
                        }}
                        pagination={paginationFactory({
                          page: currentPage,
                          sizePerPage: pageSize,
                          totalSize: totalItems,
                          sizePerPageList: [
                            { text: "5", value: 5 },
                            { text: "10", value: 10 },
                            { text: "20", value: 20 },
                          ],
                        })}
                        onTableChange={handleTableChange}
                        rowStyle={{ cursor: "pointer" }}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            );
          }}
        </Query>
      )}
    </Query>
  );
};

export default withRouter(BillingSchemaDetail);
