import React, { useState } from "react";
import { Query, Mutation } from "react-apollo";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import Form from "react-jsonschema-form";
import i18n from "../../views/Pages/Login/i18n";
import {
  findDocumentType,
  updateDocumentTypeMutation,
} from "../../queries/Queries";
import schema from "../../schemas/documentType.schema.json";
import { createUiSchema } from "../../schemas/documentType.uischema.js";
import ExpiringAlert from "../../components/ExpiringAlert";
import Loading from "../../components/Loading";

const EditDocumentType = ({ match, history }) => {
  const [alert, setAlert] = useState(null);
  const { id } = match.params;

  const handleSubmit =
    (updateDocumentType) =>
    ({ formData }) => {
      updateDocumentType({
        variables: {
          documentType: {
            id,
            name: formData.name || "",
            reference: formData.reference || "",
            executionContext: formData.executionContext || "",
            documentCategory: formData.documentCategory || "",
            created: formData.created, // Pass but don't update
            createdBy: formData.createdBy, // Pass but don't update
            tenant: formData.tenant, // Pass but don't update
            updatedBy: formData.updatedBy || "",
          },
        },
      });
    };

  return (
    <Query query={findDocumentType} variables={{ id }}>
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <p>Error! {error.message}</p>;

        const documentType = data.findDocumentType;
        const initialData = {
          name: documentType.name,
          reference: documentType.reference,
          executionContext: documentType.executionContext,
          documentCategory: documentType.documentCategory,
          created: documentType.created,
          createdBy: documentType.createdBy,
          tenant: documentType.tenant,
          updatedBy: documentType.updatedBy,
        };

        return (
          <Mutation
            mutation={updateDocumentTypeMutation}
            onCompleted={(response) => {
              history.push(`/document-types/${response.saveDocumentType.id}`);
              setAlert({
                color: "success",
                message: i18n.t("schemas.successfulUpdate"),
              });
            }}
            onError={(error) => {
              setAlert({
                color: "danger",
                message: error.message,
              });
            }}
          >
            {(updateDocumentType) => (
              <div className='edit-users'>
                <Row className='subheader'>
                  <Col className='col-8'>
                    <h1>Edit Billing Document Type</h1>
                  </Col>
                  <Col className='col-4'>
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <i className='icon ion-android-home'></i>
                        <Link to='/'>Home</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <Link to='/document-types'>Document Types</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>Edit</BreadcrumbItem>
                    </Breadcrumb>
                  </Col>
                </Row>
                <div className='modal-body'>
                  <Form
                    schema={schema}
                    uiSchema={createUiSchema(false)}
                    formData={initialData}
                    onSubmit={handleSubmit(updateDocumentType)}
                  >
                    <div className='buttons'>
                      <button type='submit' className='btn btn-primary'>
                        {i18n.t("schemas.addSchema")}
                      </button>
                      <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => history.push("/document-types/")}
                      >
                        Cancel
                      </button>
                    </div>
                    {alert && (
                      <ExpiringAlert
                        color={alert.color}
                        message={alert.message}
                      />
                    )}
                  </Form>
                </div>
              </div>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default withRouter(EditDocumentType);
