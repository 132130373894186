import React from "react";
import { Query } from "react-apollo";
import { Input } from "reactstrap";
import { getLineFactory, getProducts } from "../queries/Queries";

const CustomSelect = ({ value, onChange, options }) => {
  return (
    <Input
      type='select'
      value={value || ""}
      onChange={(event) => onChange(event.target.value)}
    >
      <option value=''> </option>
      {options.enumOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Input>
  );
};

const uischema = {
  "ui:order": ["priority", "lineFactoryName", "productId", "name"],
  lineFactoryName: {
    "ui:widget": (props) => (
      <Query query={getLineFactory} variables={{ name: "LineFactoryName" }}>
        {({ loading, error, data }) => {
          if (loading) return <Input type='select' />;
          if (error) return `Error! ${error.message}`;
          const enumOptions = data.__type.enumValues.map((v) => ({
            value: v.name,
            label: v.name,
          }));
          return (
            <CustomSelect
              value={props.value}
              onChange={props.onChange}
              options={{
                enumOptions,
              }}
            />
          );
        }}
      </Query>
    ),
  },
  productId: {
    "ui:widget": (props) => (
      <Query query={getProducts} variables={{ pageSize: 100, pageNumber: 0 }}>
        {({ loading, error, data }) => {
          if (loading) return <Input type='select' />;
          if (error) return `Error! ${error.message}`;
          const enumOptions = data.Product.content.map((v) => ({
            value: v.id,
            label: v.name,
          }));
          return (
            <CustomSelect
              value={props.value}
              onChange={props.onChange}
              options={{
                enumOptions,
              }}
            />
          );
        }}
      </Query>
    ),
  },
};

export default uischema;
