import React from "react";
import {withApollo, Query} from "react-apollo";
import gql from "graphql-tag";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import overlayFactory from "react-bootstrap-table2-overlay";
import {withRouter} from 'react-router-dom';
import Loading from "cockpit-ui-dataflows/src/components/Loading";
import ReactDOM from "react-dom";
import ExpiringAlert from "../ExpiringAlert";

const changeStatusMutation = gql`
    mutation Publish($reportId:String, $urn:String){
      publishReport(reportId:$reportId, urn:$urn){
        status
        message
      }
    }    
`

const PaginatedQueryDataGridHasura = (props) => {
    const changeStatus = async (id, urn) => {
        return await props.client.mutate({
            mutation: changeStatusMutation,
            variables: {'reportId': id, 'urn': urn},
            refetchQueries: [{query: props.query}],
        })
    }

    const routeChange = (id) => {
        let path = '/view/customer/' + id;
        props.history.push(path);
    }

    const setStyles = () => {
        return Object.entries(props.setStyles).reduce((acc, curr) => {
            acc[curr[0]] = curr[1]
            return acc
        }, {});
    }

    return (
        <div className='hartree-hasura-grid'>
            <Query query={gql`${props.query}`} variables={props.variables ? props.variables : {}}>
                {({data, error, loading}) => {
                    if (loading) return <Loading/>

                    return <BootstrapTable
                        classes={`text-${props.align} align-middle`}
                        headerClasses="th-expand"
                        wrapperClasses="table-responsive"
                        keyField={props.keyField}
                        data={data[props.dataPath]}
                        noDataIndication={() => <div>No records in table</div>}
                        columns={props.columns}
                        loading={true}
                        bordered={false}
                        defaultSorted={props.defaultSorted}
                        remote={{
                            filter: true,
                            pagination: false,
                            sort: false,
                            cellEdit: false
                        }}
                        filter={filterFactory()}
                        // overlay={ overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' }) }
                        pagination={paginationFactory({
                            page: 1,
                            sizePerPageList: props.sizePerPageList || [{
                                text: "5", value: 5
                            }, {
                                text: "10", value: 10
                            }, {
                                text: "20", value: 20
                            }],
                        })}
                        rowStyle={props.setStyles && setStyles()}
                        rowEvents={{
                            onClick: (e, row, index) => {
                                if (e.target.textContent.trim() === 'Send') {
                                    const promise = changeStatus(row.id, row.reportlink);

                                    const handleResult = function (result) {
                                        const resObj = result.data ? result.data.publishReport : result;
                                        if (!resObj.status || parseInt(resObj.status) !== 200) {
                                            ReactDOM.render(<ExpiringAlert color="danger"
                                                                           message={resObj.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                            console.log('There was an error publishing the file: ', resObj.message);
                                        } else if (resObj.status || parseInt(resObj.status) === 200) {
                                            ReactDOM.render(<ExpiringAlert color="success"
                                                                           message={resObj.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                        }
                                    };

                                    promise.catch(handleResult).then(handleResult);
                                }
                                if (row.name) {
                                    routeChange(row.id);
                                }
                            }
                        }}
                        hover
                    />
                }}
            </Query>
        </div>
    )
}

export default withRouter(withApollo(PaginatedQueryDataGridHasura))