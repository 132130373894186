import React from "react";
import { Input } from "reactstrap";

export const createUiSchema = (
  mergedParameters = {},
  includeReference = false
) => {
  const uiSchema = {
    "ui:order": includeReference
      ? ["name", "billingSchemaReference", "parameters"]
      : ["name", "billingSchemaReference", "parameters"],

    name: {
      "ui:widget": (props) => (
        <div>
          <Input
            type='text'
            value={props.value || ""}
            onChange={(event) => props.onChange(event.target.value)}
            required
          />
        </div>
      ),
    },

    parameters: {
      "ui:field": (props) => {
        return (
          <div className='d-inline-block'>
            <label className='control-label'>
              Parameters<span>*</span>
            </label>
            {Object.keys(mergedParameters).map((key) => (
              <span key={key} className='d-flex align-items-center mb-2'>
                <Input
                  className='role-field css-checkbox'
                  type='checkbox'
                  checked={props.formData[key] || false}
                  onChange={(event) => {
                    props.onChange({
                      ...props.formData,
                      [key]: event.target.checked,
                    });
                  }}
                  id={`checkbox_${key}`}
                />
                <label
                  htmlFor={`checkbox_${key}`}
                  className='css-label d-flex justify-content-between align-items-center w-100'
                  style={{ fontSize: "13px" }}
                >
                  <span
                    className='text-truncate mr-2'
                    style={{ minWidth: "150px" }}
                  >
                    {key}
                  </span>
                  <i className='icon ion-android-checkbox ml-auto mt-0'></i>
                </label>
              </span>
            ))}
          </div>
        );
      },
    },
  };

  if (includeReference) {
    uiSchema.billingSchemaReference = {
      "ui:widget": (props) => (
        <Input
          type='text'
          value={props.value || ""}
          onChange={(event) => props.onChange(event.target.value)}
          required
        />
      ),
    };
  } else {
    uiSchema.billingSchemaReference = {
      "ui:widget": "hidden", // Hide the reference field
    };
  }

  return uiSchema;
};
