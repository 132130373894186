import React from 'react';
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from 'react-router';
import i18n from '../../views/Pages/Login/i18n';
import Loading from "../../components/Loading"
import BasicQueryComponent from "../BasicQueryComponent";
import _ from "underscore";
import {
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap';
import CreateTask from "../../views/CreateTask";
import jsonata from "jsonata/jsonata-es5";
import loadable from '@loadable/component';

const LazyComponent = loadable((props) => {
 console.log("./../../" + window.config.consul.CLIENT + "/" + (props.type || "views") + "/" + props.component)
  return import("./../../" + window.config.consul.CLIENT + "/" + (props.type || "views") + "/" + props.component)
         .catch(() => import("./../../" + (props.type || "views") + "/" + props.component)
             .catch(() => import("./../../views/Missing")))
 }
)

class SiteHeader extends BasicQueryComponent {
    constructor(props) {
        super(props);

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleReadingModal = this.toggleReadingModal.bind(this);
        this.toggleTaskModal = this.toggleTaskModal.bind(this);
        this.isActionAllowed = this.isActionAllowed.bind(this);
        this.refetchModalQuery = this.refetchModalQuery.bind(this);
        this.state = {
            dropdownOpen: false,
            showCreateReadingModal: false,
            showCreateTaskModal: false,
            queryRerunIndicator: false
        };
    }


    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    toggleReadingModal() {
        this.setState({
            showCreateReadingModal: !this.state.showCreateReadingModal
        });

        if (window.gtag) {
            const location = document.location;
            const path = location.hash.substr(1) + '/popup_create_reading';
            window.gtag('config', window.config.consul.tracking["ga-code"], {
                'page_title': 'Enter Read',
                'page_path': path
            });
        }

    }

    toggleTaskModal() {
        this.setState({
            showCreateTaskModal: !this.state.showCreateTaskModal
        });

        if (window.gtag) {
            const location = document.location;
            const path = location.hash.substr(1) + '/popup_create_query';
            window.gtag('config', window.config.consul.tracking["ga-code"], {
                'page_title': 'Enter Query',
                'page_path': path
            });
        }
    }

    isActionAllowed(showActions, dataObj) {
        return !this.global.permissions.includes('Users.ReadOnly')
            && this.props.match.params.param !== 'tab_site_invoices'
            && this.props.match.params.name !== 'tariffs'
            && showActions
            && ((Array.isArray(dataObj)
                && jsonata("$boolean(account.status='A')").evaluate(dataObj[0]))
                || jsonata("$boolean(account.status='A')").evaluate(dataObj));
    }

    /**
     * Function to re-fetch the modal query when the read is submitted to handle the once-a-day submission capability.
     * This function is passed as a prop to the CreateReading component.
     * this.state.queryRerunIndicator is used in the key prop of the <Query /> from react-apollo to force a re-fetch of the query.
     */
    refetchModalQuery() {
        this.setState({...this.state, queryRerunIndicator: !this.state.queryRerunIndicator});
    }

    render() {
        let variables;
        if (this.props.match.params["variables"]) {
            variables = JSON.parse(this.props.match.params["variables"][this.props.queryKey]);
        } else {
            variables = this.props.variables;
            if (variables) {
                const compiled = _.template(JSON.stringify(variables));
                variables = JSON.parse(compiled(this.props.match.params));
            }
        }
        return (
            <Query key={this.state.queryRerunIndicator} query={gql`${this.props.queries[this.props.queryKey]}`} variables={variables}>
                {(result) => {

                    function getNested(obj, ...args) {
                        return args.reduce((obj, level) => obj && obj[level], obj)
                    }

                    if (result.loading) return (<Loading />);
                    if (result.error) return <div>{result.error} </div>;
                    const { data } = result;
                    if (Object.keys(data).length === 0) {
                        this.props.client.query({
                            query: gql`${this.props.queries[this.props.queryKey]}`,
                            variables: variables
                        });
                        return (<Loading />);
                    }
                    let expr = null;
                    if (this.props.expression) {
                        const compiled = _.template(this.props.expression);
                        expr = compiled(this.props.match.params);
                    }
                    const expression = expr ? jsonata(expr) : null;
                    const transformedData = expression ? expression.evaluate(data) : data;
                    const dataWithParents = this.setParents(transformedData, []);

                    const { children } = this.props;
                    const { queries, queryKey, variables, title, ...newProps } = this.props;
                    const dataObj = this.getFieldValue(data, this.props.dataPath);

                    let showActions = false;
                    let accountNumber;
                    let accountOrganization;

                    const accounts = [];
                    const meterPoints = [""];
                    const meterPointsData = [];
                    const sites = [];

                    let meterPoint;
                    let customer;
                    let siteName;
                    let customerName;

                    if (Array.isArray(dataObj)) {

                        for (let j = 0; j < dataObj.length; j++) {
                            if (dataObj[j].parentProperty === null) {
                                meterPoint = getNested(dataObj[j], "site", "meterPoints", "0");
                                customer = getNested(dataObj[j], "site", "meterPoints", "0", "contract", "account", "customer", "id");

                                accountNumber = dataObj[j].account.number;
                                accountOrganization = dataObj[j].account.organization;
                                accounts.push(getNested(dataObj[j], "account", "number"));

                                siteName = dataObj[j].name;
                                customerName = dataObj[j].account.customer.name;

                                sites.push(dataObj[j].site);
                                if (dataObj[j].status === 'Active') {
                                    showActions = true;
                                }
                            }

                            if (dataObj[j]) for (const i in dataObj[j].site.meterPoints) {
                                meterPoints.push(getNested(dataObj[j], "site", "meterPoints", i, "identifier"));
                                let currMp = (getNested(dataObj[j], "site", "meterPoints", i));
                                currMp.startDate = dataObj[j].startDate;
                                currMp.endDate = dataObj[j].endDate;
                                meterPointsData.push(currMp);
                            }
                            if (dataObj[j]) for (const i in dataObj[j].properties) {
                                meterPoints.push(getNested(dataObj[j], "properties", i, "site", "meterPoints", 0, "identifier"));
                                let currMp = getNested(dataObj[j], "properties", i, "site", "meterPoints", 0);
                                currMp.startDate = getNested(dataObj[j], "properties", i).startDate;
                                currMp.endDate = getNested(dataObj[j], "properties", i).endDate;
                                meterPointsData.push(currMp);
                            }
                        }
                    } else {
                        if (dataObj) {
                            siteName = dataObj.name;
                            customerName = dataObj.account.customer.name;
                            sites.push(dataObj.site);
                            accountNumber = dataObj.account.number;
                            accountOrganization = dataObj.account.organization;

                            if (dataObj.status === 'Active') {
                                showActions = true;
                                accounts.push(getNested(dataObj, "account", "number"));
                            }

                            meterPoint = getNested(dataObj, "site", "meterPoints", "0");
                            customer = getNested(dataObj, "site", "meterPoints", "0", "contract", "account", "customer", "id");
                        }

                        if (dataObj) for (const i in dataObj.site.meterPoints) {
                            meterPoints.push(getNested(dataObj, "site", "meterPoints", i, "identifier"));
                            let currMp = (getNested(dataObj, "site", "meterPoints", i));
                            currMp.startDate = dataObj.startDate;
                            currMp.endDate = dataObj.endDate;
                            meterPointsData.push(currMp);
                        }
                        if (dataObj) for (const i in dataObj.properties) {
                            meterPoints.push(getNested(dataObj, "properties", i, "site", "meterPoints", 0, "identifier"));
                            let currMp = getNested(dataObj, "properties", i, "site", "meterPoints", 0);
                            currMp.startDate = getNested(dataObj, "properties", i).startDate;
                            currMp.endDate = getNested(dataObj, "properties", i).endDate;
                            meterPointsData.push(currMp);
                        }
                    }

                    let type = null;

                    if (sites && sites.length && sites[0].meterPoints && sites[0].meterPoints.length) {
                        type = sites[0].meterPoints[0].purpose;
                    }
                    customerName = customerName && customerName.trim();
                    siteName = siteName && siteName.trim();
                    return dataObj ? (
                        <div className='bp busines-parnter-cnt'>
                            <Row className='subheader'>
                                <Col lg="8" xs="8">
                                    <h1>{customerName || ""}</h1>
                                </Col>
                                <Col lg="4" xs="4">
                                {
                                    this.isActionAllowed(showActions, dataObj) &&
                                        (this.props.showNewReadingBtnOnly ? (
                                            <Button
                                                className="pull-right"
                                                color="primary"
                                                onClick={() => this.setState({ showCreateReadingModal: true })}
                                            >
                                                <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{"Submit Reading"}
                                            </Button>
                                        ) : (
                                            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                                {!this.props.hideQueryBtn && (
                                                    <DropdownToggle className="pull-right" color="primary">
                                                        <i className="icon ion-plus-round"></i>&nbsp;&nbsp;
                                                        {i18n.t("header.new")}
                                                    </DropdownToggle>
                                                )}
                                                <DropdownMenu>
                                                    <DropdownItem
                                                        onClick={() => this.setState({ showCreateReadingModal: true })}
                                                    >
                                                        Reading
                                                    </DropdownItem>
                                                    {this.props.canAddNewQuery && (
                                                        <DropdownItem
                                                            onClick={() => this.setState({ showCreateTaskModal: true })}
                                                        >
                                                            Query
                                                        </DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </Dropdown>
                                        ))
                                }
                                </Col>
                            </Row>
                            {this.state.showCreateReadingModal && (
                                <Modal
                                    size={"large"}
                                    toggle={this.toggleReadingModal}
                                    isOpen={this.state.showCreateReadingModal}
                                    style={{ maxWidth: "500px", width: "90%" }}
                                >
                                    <ModalHeader toggle={this.toggleReadingModal}>
                                        Create Reading
                                    </ModalHeader>
                                    <ModalBody>
                                        <LazyComponent
                                            component={"CreateReading/CreateReading"}
                                            estateList={sites}
                                            account={accountNumber}
                                            toggle={this.toggleReadingModal}
                                            refetchModalQuery={this.refetchModalQuery}
                                            meterPoints={meterPointsData}
                                            bpEstateId={dataObj.reduce(
                                                (acc, property) => (
                                                    (acc[property.name] = property.id), acc
                                                ),
                                                {}
                                            )} // map to an object with estate name (DY) as key and estateId as value
                                        />
                                    </ModalBody>
                                </Modal>
                            )}
                            {this.state.showCreateTaskModal && (
                                <CreateTask
                                    modalOpen={this.state.showCreateTaskModal}
                                    meterPoints={meterPoints}
                                    meterPoint={meterPoint}
                                    meterPointsData={meterPointsData}
                                    onClose={this.toggleTaskModal}
                                    customer={customer}
                                    accounts={accounts}
                                />
                            )}
                        </div>
                    ) : null
                }}
            </Query>
        );
    }
}

export default withRouter(SiteHeader);
