import React, { useState } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import { Mutation } from "react-apollo";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";
import i18n from "../../views/Pages/Login/i18n";
import { saveDocumentTypeMutation } from "../../queries/Queries";
import Form from "react-jsonschema-form";
import schema from "../../schemas/documentType.schema.json";
import { createUiSchema } from "../../schemas/documentType.uischema.js";
import ExpiringAlert from "../../components/ExpiringAlert";

const CreateDocumentType = ({ history }) => {
  const [formData, setFormData] = useState({
    name: "",
    reference: "",
    executionContext: "",
    documentCategory: "",
  });

  const handleSubmit = (saveDocumentType) => (formData) => {
    saveDocumentType({
      variables: {
        documentType: {
          name: formData.name,
          reference: formData.reference,
          executionContext: formData.executionContext,
          documentCategory: formData.documentCategory,
        },
      },
    })
      .then((response) => {
        const documentId = response.data.saveDocumentType.id;
        history.push(`/document-types/${documentId}`);
        renderAlert(i18n.t("schemas.successfulCreate"), "success");
        setFormData({
          name: "",
          reference: "",
          executionContext: "",
          documentCategory: "",
        });
      })
      .catch((error) => {
        console.error("Error saving document type", error);
        renderAlert(error.message, "danger");
      });
  };

  const renderAlert = (message, color) => {
    ReactDOM.render(
      <ExpiringAlert color={color} message={message} />,
      document.getElementById("alert")
    );
  };

  const uiSchema = createUiSchema(true);

  return (
    <div className='edit-users'>
      <Row className='subheader'>
        <Col className='col-8'>
          <h1>Add New Billing Document Type</h1>
        </Col>
        <Col className='col-4'>
          <Breadcrumb>
            <BreadcrumbItem>
              <i className='icon ion-android-home'></i>
              <a href='#'>Home</a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a href='#/document-types'>Document Types</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>Create</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <div>
        <div className='modal-body'>
          <Mutation mutation={saveDocumentTypeMutation}>
            {(saveDocumentType, { error }) => (
              <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                onChange={({ formData }) => setFormData(formData)}
                onSubmit={({ formData }) =>
                  handleSubmit(saveDocumentType)(formData)
                }
              >
                <div className='buttons'>
                  <button type='submit' className='btn btn-primary'>
                    {i18n.t("schemas.addSchema")}
                  </button>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={() => history.push("/document-types/")}
                  >
                    Cancel
                  </button>
                </div>
                {error && renderAlert(error.message, "danger")}
              </Form>
            )}
          </Mutation>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CreateDocumentType);
