import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import { withApollo } from "react-apollo";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Form from "react-jsonschema-form";
import ExpiringAlert from "../../components/ExpiringAlert";
import schema from "../../schemas/documentLine.schema.json";
import uischema from "../../schemas/documentLine.uischema";
import { saveDocumentLine } from "../../queries/Queries";

const DocumentLineModal = ({
  client,
  isOpen,
  onClose,
  documentType,
  refetchDocumentDetails,
}) => {
  const defaultForm = {
    priority: 1,
    lineFactoryName: "",
    productId: "",
    name: "",
  };
  const [formData, setFormData] = useState(defaultForm);

  const toggleClose = () => {
    setFormData(defaultForm);
    onClose();
  };

  const renderAlert = (message, color) => {
    ReactDOM.render(
      <ExpiringAlert color={color} message={message} />,
      document.getElementById("alert")
    );
  };

  const validate = (formData, errors) => {
    if (formData.priority <= 0) {
      errors.priority.addError("Priority must be greater than 0");
    }
    if (!formData.lineFactoryName) {
      errors.lineFactoryName.addError("Line Factory is required");
    }
    if (!formData.productId) {
      errors.productId.addError("Product is required");
    }
    if (!formData.name) {
      errors.name.addError("Name is required");
    }
    return errors;
  };

  const handleSubmit = () => {
    client
      .mutate({
        mutation: saveDocumentLine,
        variables: {
          documentId: documentType.id,
          lineFactoryName: formData.lineFactoryName,
          productId: formData.productId,
          name: formData.name,
          priority: formData.priority,
        },
      })
      .then(() => {
        refetchDocumentDetails().then(() => toggleClose());
      })
      .catch((error) => {
        console.error("Error saving document line", error);
        renderAlert(error.message, "danger");
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleClose}
      size='lg'
      className='create-nomination-modal'
      backdrop='static'
    >
      <ModalHeader toggle={toggleClose}>Create Document Line</ModalHeader>
      <ModalBody>
        <Fragment>
          <Form
            schema={schema}
            uiSchema={uischema}
            formData={formData}
            onChange={({ formData }) => setFormData(formData)}
            onSubmit={() => handleSubmit()}
            validate={validate}
            showErrorList={false}
          >
            <Fragment>
              <div className='modal-footer'>
                <button type='submit' className='btn btn-primary'>
                  Submit
                </button>
                <button
                  type='button'
                  className='btn btn-secondary'
                  onClick={() => toggleClose()}
                >
                  Cancel
                </button>
              </div>
            </Fragment>
          </Form>
        </Fragment>
      </ModalBody>
    </Modal>
  );
};

export default withApollo(DocumentLineModal);
