import React from 'react';
import {getMeterPointsDynamic} from "../../../../queries/Queries";
import Loading from "../../../../components/Loading";
import ErrorPage from "../../../../components/ErrorPage/ErrorPage";
import { Query } from "react-apollo";
import { Col, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { dateFormatter, tickFormatter } from '../../../../utils/Formatters';

const MeterPointsList = () => {

    return (
        <div>
			<Row className="subheader">
				<Col className="col-8">
					<h1>Meter Points List</h1>
				</Col>
			</Row>
			<Row className="dashboard-content my-3 p-3">
				<Col className="col-12">
					<Query query={getMeterPointsDynamic}>
						{({
							loading,
							error,
							data: { meter_points_dynamic: queryData },
						}) => {
							if (loading) return <Loading />;
							if (error) return <ErrorPage />;
							return (
									<BootstrapTable
										headerClasses="custom-header-class"
                    					wrapperClasses="table-responsive"
										noDataIndication={() => (
											<div>No records in table.</div>
										)}
										columns={[
											{
												dataField: "si_bpartner",
												text: "Business Partner",
												sort: true,
											},
											{
												dataField: "meter_point",
												text: "Meter Point",
												sort: true,
											},
											{
												dataField: "is_in_360",
												text: "360",
												sort: true,
                                                formatter: tickFormatter
											},
											{
												dataField: "epsilon_hartree",
												text: "Epsilon / Hartree system",
												sort: true,
                                                headerStyle: { whiteSpace: "unset" },
		                                        style: { width: "7%" },
                                                formatter: tickFormatter
											},
											{
												dataField: "epsilon_haretree_product",
												text: "Epsilon / Hartree product",
												sort: true,
                                                headerStyle: { whiteSpace: "unset" },
		                                        style: { width: "7%" },
											},
                                            {
                                                dataField: "si_product",
												text: "Service",
												sort: true
                                            }, 
                                            {
                                                dataField: "si_start_date",
												text: "Start Date",
												sort: true,
                                                formatter: dateFormatter,
                                            }
										]}
										data={queryData}
										keyField={"id"}
										loading={loading}
										bordered={false}
										hover
										remote={{
											filter: true,
											pagination: false,
											sort: false,
											cellEdit: false,
										}}
                                        pagination={paginationFactory({
                                            page: 1,
                                            sizePerPage: 20,
                                            sizePerPageList: [
                                                {
                                                    text: "5",
                                                    value: 5,
                                                },
                                                {
                                                    text: "10",
                                                    value: 10,
                                                },
                                                {
                                                    text: "20",
                                                    value: 20,
                                                },
                                            ],
                                            showTotal: true,
                                        })}
									/>
							);
						}}
					</Query>
				</Col>
			</Row>
		</div>
    )
}
export default MeterPointsList;