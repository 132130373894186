import React, { useState } from "react";
import { Query, Mutation } from "react-apollo";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import Form from "react-jsonschema-form";
import i18n from "../../views/Pages/Login/i18n";
import {
  getBillingSchemaDetailsQuery,
  updateBillingSchemaMutation,
  findAllBillingSchemaParameters,
} from "../../queries/Queries";
import schema from "../../schemas/billingSchema.schema.json";
import { createUiSchema } from "../../schemas/billingSchema.uischema.js";
import ExpiringAlert from "../../components/ExpiringAlert";
import Loading from "../../components/Loading";

const BillingSchemaEdit = ({ match, history }) => {
  const [alert, setAlert] = useState(null);
  const { id } = match.params;

  const handleSubmit =
    (saveBillingSchema) =>
    ({ formData }) => {
      const variables = {
        id,
        created: formData.created,
        createdBy: formData.createdBy,
        updated: formData.updated,
        updatedBy: formData.updatedBy || "",
        name: formData.name || "",
        tenant: formData.tenant || "",
        docTypes: formData.docTypes || [],
        parameters: formData.parameters || {},
        billingSchemaReference: formData.billingSchemaReference,
      };

      saveBillingSchema({ variables })
        .then(() => {
          setAlert({
            color: "success",
            message: i18n.t("schemas.successfulUpdate"),
          });
          history.push(`/schema-document/${id}`);
        })
        .catch((error) => {
          setAlert({
            color: "danger",
            message: error.message,
          });
        });
    };

  return (
    <Query query={getBillingSchemaDetailsQuery} variables={{ id }}>
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <p>Error! {error.message}</p>;

        const billingSchema = data && data.findBillingSchema;
        if (!billingSchema) {
          return <p>Billing schema not found.</p>;
        }

        const initialData = {
          name: billingSchema.name || "",
          tenant: billingSchema.tenant || "",
          docTypes: billingSchema.docTypes || [],
          parameters: billingSchema.parameters || {},
          created: billingSchema.created,
          createdBy: billingSchema.createdBy,
          updatedBy: billingSchema.updatedBy,
          billingSchemaReference: billingSchema.billingSchemaReference,
        };

        return (
          <Query query={findAllBillingSchemaParameters}>
            {({
              loading: typesLoading,
              error: typesError,
              data: typesData,
            }) => {
              if (typesLoading) return <Loading />;
              if (typesError)
                return (
                  <p>Error loading document types! {typesError.message}</p>
                );

              const availableParameters = new Set();

              typesData.BillingSchema.content.forEach((billingSchema) => {
                Object.keys(billingSchema.parameters || {}).forEach((param) => {
                  availableParameters.add(param);
                });
              });

              const mergedParameters = {
                ...Array.from(availableParameters).reduce((acc, param) => {
                  acc[param] = false;
                  return acc;
                }, {}),
                ...initialData.parameters,
              };

              initialData.parameters = mergedParameters;

              const uiSchema = createUiSchema(mergedParameters, false);

              return (
                <Mutation mutation={updateBillingSchemaMutation}>
                  {(saveBillingSchema) => (
                    <div className='edit-billing-schema'>
                      <Row className='subheader'>
                        <Col className='col-8'>
                          <h1>Edit Billing Schema Document</h1>
                        </Col>
                        <Col className='col-4'>
                          <Breadcrumb>
                            <BreadcrumbItem>
                              <i className='icon ion-android-home'></i>
                              <Link to='/'>Home</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                              <Link to='/schema-document'>Billing Schemas</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Edit</BreadcrumbItem>
                          </Breadcrumb>
                        </Col>
                      </Row>
                      <div className='modal-body'>
                        <Form
                          schema={schema}
                          uiSchema={uiSchema}
                          formData={initialData}
                          onSubmit={handleSubmit(saveBillingSchema)}
                        >
                          <div className='buttons'>
                            <button type='submit' className='btn btn-primary'>
                              {i18n.t("schemas.addSchema")}
                            </button>
                            <button
                              type='button'
                              className='btn btn-secondary'
                              onClick={() => history.push("/schema-document/")}
                            >
                              Cancel
                            </button>
                          </div>
                          {alert && (
                            <ExpiringAlert
                              color={alert.color}
                              message={alert.message}
                            />
                          )}
                        </Form>
                      </div>
                    </div>
                  )}
                </Mutation>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

export default withRouter(BillingSchemaEdit);
