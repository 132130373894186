import React, { useState } from 'react';
import i18n from '../../views/Pages/Login/i18n';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import axios from '../../utils/Client';

const CatalogPricesForm = ({ setShowModal }) => {
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [fileUrl, setFileUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    setFileUrl(e.target.files[0]);
  };

  const fileUpload = () => {
    const url = window.config.consul.TARIFFS_URL + '/catalog/upload';
    const formData = new FormData();

    formData.append('name', name);
    formData.append('file', fileUrl);
    formData.append('organizationId', window.config.consul.CLIENT);
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
        'content-type': 'multipart/form-data',
      },
    };
    return axios.post(url, formData, config);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    fileUpload()
      .then((res) => {
        setIsLoading(false);
        setShowModal(false);
      })
      .catch((err) => console.log('catched', err));
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className='organization'>
      <div>
        <Row className='subheader'>
          <Col className='col-8'>
            <h1>Catalog Prices</h1>
          </Col>
        </Row>
      </div>
      <div className='page-cnt'>
        <Form className='step upload-Tariffs' onSubmit={handleSubmit}>
          <Row>
            <Col className='col-12 step-header'>
              <h2>Upload Prices File</h2>
            </Col>
          </Row>
          <Row>
            <Col className='col-6'>
              <FormGroup>
                <Row>
                  <Col xs='4'>
                    <Label className='user-label'>Name</Label>
                  </Col>
                  <Col xs='8'>
                    <Input
                      type='text'
                      value={name}
                      placeholder='Enter Price Name'
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row className='align-items-center'>
                  <Col xs='4'>
                    <Label for='startDate'>Start Date</Label>
                  </Col>
                  <Col xs='8'>
                    <Input
                      id='startDate'
                      name='date'
                      placeholder='Start date..'
                      type='date'
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row className='align-items-center'>
                  <Col xs='4'>
                    <Label for='endDate'>End Date</Label>
                  </Col>
                  <Col xs='8'>
                    <Input
                      id='endDate'
                      name='date'
                      placeholder='End date..'
                      type='date'
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs='4'>
                    <Label className='user-label'>Attach File</Label>
                  </Col>
                  <Col xs='8'>
                    <Input
                      type='file'
                      label='Upload'
                      style={{ display: 'inline' }}
                      onChange={handleFileChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row className='btn-cnt'>
            <Col xs='12' sm='12' className='spacer'></Col>
            <Col xs='4' sm='4' md='2' lg='2' xl='2'></Col>
            <Col xs='8' sm='8'>
              <Button color='primary' type='submit' disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Upload'}
              </Button>
              &nbsp;
              <Input
                className='btn btn-secondary'
                type='button'
                value={i18n.t('header.cancel')}
                onClick={handleCloseModal}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default CatalogPricesForm;
