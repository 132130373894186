import React , {Component,Fragment} from "reactn";
import {Dropdown,DropdownToggle,DropdownMenu,DropdownItem} from "reactstrap";
import {withApollo} from "react-apollo";
import {withRouter} from "react-router-dom";
import gql from "graphql-tag";
import moment from "moment";

class ChangeStatusDropdown extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.onClick = this.handleChange.bind(this);
        this.state = {
            dropdownOpen: false,
            dropdownItems: null,
            items: null,
            currentStatus: this.props.currentStatus
        };
    }


    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    handleChange(e){
        e.preventDefault();
        const newValue = e.target.value;
        this.props.client.mutate({
            mutation: gql`${this.props.queries[this.props.mutationKey]}`,
            variables: {
                status: newValue,
                id: this.props.id,
                ...(this.props.mutationKey === "updateTask" && {
                    closed_by: newValue === "Closed" ? this.global.user.username : "",
                    closed_on: newValue === "Closed" ? moment() : ""
                })
            }
        }).catch((error) => {alert(error.message)})
        this.setState({currentStatus: newValue})
    }
    render(){
        if(this.props.statuses){
            this.state.items = this.props.statuses.map(status => (
                <DropdownItem onClick={this.onClick} key={this.props.id} value={status}>{status}</DropdownItem>
            ))
        }
        return (
            <Fragment>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} >
                    <DropdownToggle caret>
                        {this.state.currentStatus}
                    </DropdownToggle>
                    <DropdownMenu>
                        {this.state.items}
                    </DropdownMenu>
                </Dropdown>
            </Fragment>
        )
    }
}

export default withApollo(ChangeStatusDropdown);