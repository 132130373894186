import React from 'reactn';
import { useState } from 'react';
import CatalogPricesForm from './CatalogPricesForm';
import CatalogPricesTableView from './CatalogPricesTableView';

const CatalogPricesView = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    showModal ? <CatalogPricesForm setShowModal={setShowModal} /> : <CatalogPricesTableView setShowModal={setShowModal}/>
  );
};

export default CatalogPricesView;
