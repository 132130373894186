import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Col, Input, InputGroup, Row } from "reactstrap";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import { withApollo } from "react-apollo";
import { CSVLink } from "react-csv";

import {
	OB_createGasReading,
	updateGasReading,
	weeklyValidationQuery,
} from "../../../../queries/Queries";
import Loading from "../../../../components/Loading";
import ExpiringAlert from "../../../../components/ExpiringAlert/ExpiringAlert";

import { columns, READ_STATUS } from "./columns";

const WeeklyValidation = ({ client }) => {
	const [weeklyValidationData, setWeeklyValidationData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
    // Set the state for the mutation performed
    const [mutationPerformed, setMutationPerformed] = useState(false);
	const [filteredData, setFilteredData] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [dateRange, setDateRange] = useState({
		startDate: null,
		endDate: null,
	});
	const [loading, setLoading] = useState(false);
	const [focus, setFocus] = useState(null);

	/**
	 * Fetches the data from the server and updates the state when the component mounts or the mutation is performed.
	 * @param {Function} useEffect - React hook that triggers side effects in function components.
	 * @param {Array} weeklyValidationData - The weekly validation data.
     * @param {Boolean} mutationPerformed - The mutation performed state.
	 */
	useEffect(() => {
		setLoading(true);
		client
			.query({
				query: weeklyValidationQuery,
				fetchPolicy: "no-cache",
			})
			.then((res) => setWeeklyValidationData(res.data.gas_readings))
			.catch((error) => console.log("Error", error))
			.finally(() => {
				setLoading(false);
                setMutationPerformed(false);
			});
	}, [mutationPerformed]);

	/**
	 * Filters the data based on the search term and date range and data status.
	 * @param {Function} useEffect - React hook that triggers side effects in function components.
	 * @param {Array} weeklyValidationData - The weekly validation data.
	 * @param {String} searchTerm - The search term.
	 * @param {Object} dateRange - The date range.
	 * @param {Array} filteredData - The filtered data.
	 * @returns {Array} The filtered data.
	 */
	useEffect(() => {
		let data = [];
		const dateFilter = (date) => {
			return moment(date).isBetween(
				moment(dateRange.startDate).format("MM/DD/YYYY"),
				moment(dateRange.endDate),
				undefined,
				"[]"
			);
		};

		if (searchTerm.length > 0 && dateRange.startDate && dateRange.endDate) {
			data = weeklyValidationData.filter(
				(el) =>
					(el.mprn.includes(searchTerm) && dateFilter(el.date)) ||
					(el.site.includes(searchTerm) && dateFilter(el.date))
			);
		} else if (searchTerm.length > 0) {
			data = weeklyValidationData.filter(
				(el) =>
					el.mprn.includes(searchTerm) ||
					el.site.trim().toLowerCase().includes(searchTerm.toLowerCase())
			);
		} else if (dateRange.startDate && dateRange.endDate) {
			data = weeklyValidationData.filter((el) => dateFilter(el.date));
		} else {
			data = weeklyValidationData.filter(
				(el) => READ_STATUS[el.status] === READ_STATUS.PENDING_APPROVAL
			);
		}

		setFilteredData(data);
	}, [
		searchTerm,
		dateRange.startDate && dateRange.endDate,
		weeklyValidationData,
	]);

	/**
	 * Handles the action when a report is clicked.
	 * @param {string} mprn - The MPRN value.
	 * @param {string} date - The date value.
	 * @param {string} status - The status value.
	 */
	const updateStatus = (mprn, date, status) => {
		setLoading(true);
		client
			.mutate({
				variables: { mprn: mprn, date: date, status: status },
				mutation: updateGasReading,
				refetchQueries: [{ query: weeklyValidationQuery }],
			})
			.catch((error) => console.log("Error", error))
			.finally(() => {
				setLoading(false);
                setMutationPerformed(!mutationPerformed);
			});
	};

	const submitReading = (params) => {
		setLoading(true);

		let alertColor = "success";
		let alertMsg = "Reading submitted successfully";

		return client
			.mutate({
				variables: { ...params },
				mutation: OB_createGasReading,
			})
			.catch((error) => {
				alertColor = "danger";
				alertMsg = error.message.split(" : ")[1];
			})
			.finally(() => {
				setLoading(false);
				ReactDOM.render(
					<ExpiringAlert color={alertColor} message={alertMsg} />,
					document.getElementById("alert")
				);
			});
	};

	/**
	 * Retrieves the headers for the columns.
	 * @returns {Array} An array of header objects containing the label and key.
	 */
	const getHeaders = () => {
		const headers = [];
		for (const col of tableColumns) {
			if (col.dataField) headers.push({ label: col.text, key: col.dataField });
		}
		return headers;
	};

	/**
	 * Generates a file name based on the provided date range.
	 * If the end date is available, the file name will be in the format "startDate endDate.csv".
	 * If the end date is not available, the file name will be "WeeklyValidation_Export.csv".
	 * @returns {string} The generated file name.
	 */
	const handleFileName = () => {
		if (dateRange.endDate) {
			const startDate = new Date(dateRange.startDate).toLocaleDateString();
			const endDate = new Date(dateRange.endDate).toLocaleDateString();
			return `${startDate} ${endDate}.csv`;
		}
		return "WeeklyValidation_Export.csv";
	};

	const tableColumns = columns(updateStatus, submitReading);

	const handlePageChange = page => setCurrentPage(page);

	return (
		<div>
			<Row className="subheader">
				<Col className="col-8">
					<h1>Weekly Validation</h1>
				</Col>
			</Row>
			<div className="dashboard-content hartree-hasura-grid">
				<div className="table-filers-cnt">
					<div className="table-filers-elements">
						<Row>
							<Col xs="6" sm="6">
								<InputGroup className="search">
									<Input
										placeholder="Search by MPRN or Site"
										onChange={(e) => setSearchTerm(e.target.value)}
									/>
									<i className="icon ion-ios-search-strong" />
								</InputGroup>
							</Col>
							<Col xs="4" sm="4">
								<DateRangePicker
									small
									startDate={dateRange.startDate}
									endDate={dateRange.endDate}
									onDatesChange={(date) => setDateRange(date)}
									displayFormat={"MM/DD/YYYY"}
									focusedInput={focus}
									onFocusChange={(focus) => setFocus(focus)}
									showClearDates={true}
									showDefaultInputIcon={true}
									isOutsideRange={() => false}
									noBorder={false}
								/>
							</Col>
							<Col xs="2" sm="2" className="d-flex justify-content-end">
								<CSVLink
									className="export-csv"
									filename={handleFileName()}
									data={filteredData.sort(
										(a, b) => new Date(b.date) - new Date(a.date)
									)}
									headers={getHeaders()}
								>
									<Button color="primary">Download CSV</Button>
								</CSVLink>
							</Col>
						</Row>
					</div>
				</div>

				<div>
					<ul class="efficiencyMethodLegend">
						<li>
							<span class="cns"></span> CNS Corrected Consumption
						</li>
						<li>
							<span class="rd1"></span> RD1 Corrected Consumption
						</li>
					</ul>
				</div>

				{loading ? (
					<Loading />
				) : (
					<BootstrapTable
						headerClasses="custom-header-class"
						wrapperClasses="table-responsive"
						keyField="id"
						columns={tableColumns}
						data={filteredData}
						defaultSorted={[
							{
								dataField: "date",
								order: "desc",
							},
						]}
						noDataIndication={() => <div>No records in table</div>}
						remote={{
							filter: true,
							pagination: false,
							sort: false,
							cellEdit: false,
						}}
						pagination={paginationFactory({
							page: currentPage,
							sizePerPage: 50,
							sizePerPageList: [
								{
									text: "5",
									value: 5,
								}, {
									text: "10",
									value: 10,
								}, {
									text: "20",
									value: 20,
								},{
									text: "50",
									value: 50,
								}, {
									text: "75",
									value: 75,
								}, {
									text: "100",
									value: 100,
								},
							],
							showTotal: true,
							onPageChange: handlePageChange
						})}
					/>
				)}
			</div>
		</div>
	);
};

export default withApollo(WeeklyValidation);
