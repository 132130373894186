import React from "react";
import DocumentTypesEdit from "../../components/BillingDocumentTypesEdit/DocumentTypeEdit";

const DocumentTypesEditView = () => {
  return (
    <div>
      <DocumentTypesEdit />
    </div>
  );
};

export default DocumentTypesEditView;
