import React, {Component} from 'reactn';
import {
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown
} from 'reactstrap';
import {Link} from 'react-router-dom';
import i18n from '../../views/Pages/Login/i18n';

class HeaderDropdown extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    dropAccnt() {
        console.log('this.global.user.avatarUrl', this.global.user && this.global.user.avatarUrl ? this.global.user.avatarUrl : `${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/images/placeholder`);
        console.log('CORE_URL', (window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' )));
        return (
            <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle nav>
                    <img className={"dropdown-avatar-image"} src={this.global.user && this.global.user.avatarUrl ? this.global.user.avatarUrl : `${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/images/placeholder`} alt={this.global.user ? this.global.user.username : ""}/>
                    <i className="icon ion-android-arrow-dropdown"></i>
                </DropdownToggle>
                <DropdownMenu right>
                    {/*<DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>*/}
                    {/*<DropdownItem><i className="fa fa-bell-o"></i>Updates<Badge color="info">42</Badge></DropdownItem>*/}
                    {/*<DropdownItem><i className="fa fa-envelope-o"></i>Messages<Badge*/}
                    {/*color="success">42</Badge></DropdownItem>*/}
                    {/*<DropdownItem><i className="fa fa-tasks"></i>Tasks<Badge color="danger">42</Badge></DropdownItem>*/}
                    {/*<DropdownItem><i className="fa fa-comments"></i>Comments<Badge*/}
                    {/*color="warning">42</Badge></DropdownItem>*/}
                    <DropdownItem header tag="div" className="text-center"><strong>{i18n.t('header.settings')}</strong></DropdownItem>
                    {(window.config.consul.CLIENT || (typeof CLIENT !== 'undefined' ? CLIENT : '' )) !== "metro" &&
                        <DropdownItem tag={Link} to={"/users/edituser/"+this.global.user.username}><i className="fa fa-user"></i>{i18n.t('header.profile')}</DropdownItem>
                    }
                    {/*<DropdownItem><i className="fa fa-wrench"></i>Settings</DropdownItem>*/}
                    {/*<DropdownItem><i className="fa fa-usd"></i> Payments<Badge*/}
                    {/*color="secondary">42</Badge></DropdownItem>*/}
                    {/*<DropdownItem><i className="fa fa-file"></i>Projects<Badge*/}
                    {/*color="primary">42</Badge></DropdownItem>*/}
                    {/*<DropdownItem divider/>*/}
                    {/*<DropdownItem><i className="fa fa-shield"></i>Lock Account</DropdownItem>*/}
                    <DropdownItem tag={Link} to="/changepassword"><i className="fa fa-key"></i>{i18n.t('header.passwordChange')}</DropdownItem>
                    <DropdownItem tag={Link} to="/logout"><i className="fa fa-lock"></i>{i18n.t('header.logout')}</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }

    render() {
        const {...attributes} = this.props;
        return (
            this.dropAccnt()
        );
    }
}

export default HeaderDropdown;
