import React, {Component, useCallback, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {withRouter} from 'react-router';
import gql from "graphql-tag";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import ModalDialog from "../../components/ModalDialog";
import {Mutation} from "react-apollo";
import Form from "react-jsonschema-form";
import i18n from "../Pages/Login/i18n";
import {
    createTaskMutation,
    getTasksQuery, MyQueries,
} from "../../queries/Queries";
import ExpiringAlert from "../../components/ExpiringAlert";
import TasksAttachments from "../../components/TasksAttachments";
import BOPuischema from "../../schemas/task.BOP.uischema.js";
import SSPuischema from '../../schemas/task.SSP.uischema';
import schemaHartree from "../../schemas/task-hartree.schema";
import schema from '../../schemas/task-dyce.schema';
import {getSchemaCreteQuery, getType} from "../../utils/Helpers";

const CreateTask = (props) => {
    const taskAtachmentsRef = useRef(null);
    const [isMinimized, setIsMinimized] = useState(false);
    const [loading, setLoading] = useState(false);
    const [customerMeterPoints, setCustomerMeterPoints] = useState([]);
    const [customer, setCustomer] = useState('');
    const [formData, setFormData] = useState(false);
    
    const handleChanges = (key, value) => {
        key === 'customerMeterPoints' && setCustomerMeterPoints(value);
        key === 'customer' && setCustomer(value);
    };
    
    const handleSuccessfulCreateTaskMessage = () => {
        const isDyceSSP = window.config.consul.CLIENT === 'dyce-ssp';

        if (isDyceSSP) {
            return (
                <div className='successful-task-message'>
                    <p>Thank you for your query.</p>
                    <p>We will contact you shortly. In the meantime, you might find an answer to your query from our website. Here's some help below.</p>
                    <p className='faq'>FAQ's:</p>
                    <a href='https://dyce-energy.co.uk/faqs/' target='_blank'>www.dyce-energy.co.uk/faqs</a>
                    <p className='useful-doc'>Useful Documents:</p>
                    <a href='https://dyce-energy.co.uk/downloads/' target='_blank'>www.dyce-energy.co.uk/downloads</a>
                    <p className='thanks-message'>Thanks</p>
                    <p> Dyce Energy</p>
                    <p>*Please note that our offices opening hours are 9am-5pm Mon- Thur and 9am-4pm Fri, we will close for UK Bank Holidays. 
                        If you have an emergency please see our website for emergency contact information: 
                        <a href='https://dyce-energy.co.uk/emergency/' target='_blank'> www.dyce-energy.co.uk/emergency/</a>
                    </p>
                </div>
            )
        } else {
            return i18n.t('tasks.successfulCreate');
        }
    }

    const setAccount_code = useCallback((value) => {
        setFormData((prevState) => ({
            ...prevState,
            account_code: value.split(',')[0],
            consumer_number: value.split(',')[1],
            ...(prevState.mprn && {mprn: ''})
        }));
    }, [formData])

    return (
        <Modal isOpen={props.modalOpen} toggle={props.onClose} size={"large"}
               modalClassName={isMinimized ? "minimized" : ""} className="create-taks-modal">
            <ModalHeader toggle={props.onClose}>

                <button className="btn btn-primary btn-minimize" onClick={() => setIsMinimized(!isMinimized)}><i
                    className={isMinimized ? "icon ion-android-arrow-up" : "icon ion-android-arrow-down"}></i>
                </button>
            </ModalHeader>
            <ModalBody>
                <div>
                    <div className="task-labels clearfix">
                        <span className="pull-left">{i18n.t('tasks.addTask')}</span>
                    </div>
                    <Mutation mutation={gql`${createTaskMutation}`} refetchQueries={[{
                        query: MyQueries, variables: {
                            "pageNumber": 0,
                            "pageSize": 10,
                            "where": "((variables.status==Open))",
                            "sort": {
                                "orders": [
                                    {
                                        "property": "createTime",
                                        "direction": "desc"
                                    }
                                ]
                            }
                        }
                    }]}>
                        {(createTask, {data}) => (
                            <Form
                                schema={window.config.consul.PRODUCT === 'BOP' ? schemaHartree : getSchemaCreteQuery(schema, props.meterPointsData)}
                                uiSchema={window.config.consul.PRODUCT === 'BOP' ? BOPuischema : SSPuischema}
                                transformErrors={errors => errors.map(err => {
                                    if (err.message.startsWith("should match format")) {
                                        const format = err.message.substring()
                                        switch (format.substring(20)) {
                                            case '"lettersOnly"' :
                                                err.message = "should contain only letters";
                                                break;
                                            case    '"phone"':
                                                err.message = "should contain only numbers";
                                                break;
                                            case    '"numbersOnly"':
                                                err.message = "should contain only numbers";
                                                break;
                                            case    '"lettersAndNumbers"':
                                                err.message = "should contain only letters and numbers";
                                                break;
                                        }
                                    }

                                    return err;
                                })}
                                formData={formData}
                                formContext={{
                                    setAccount_code: setAccount_code,
                                    handleChanges: handleChanges,
                                    onChange: props.onChange,
                                    customerMeterPoints: customerMeterPoints,
                                    accountCode: formData.account_code,
                                    customerId: customer,
                                    accounts: props.accounts,
                                    ...(props.properties && {properties: props.properties})
                                }}
                                onChange={e => setFormData(e.formData)}
                                onSubmit={({formData}) => {

                                    setLoading(true)

                                    const variables = [
                                        {
                                            name: "type",
                                            type: "string",
                                            value: formData.type + ""
                                        },
                                        {
                                            name: "status",
                                            type: "string",
                                            value: formData.status
                                        },
                                        {
                                            name: "contact_name",
                                            type: "string",
                                            value: formData.contact_name,
                                        },
                                        {
                                            name: "contact_number",
                                            type: "string",
                                            value: formData.contact_number
                                        },
                                        {
                                            name: "contact_email",
                                            type: "string",
                                            value: formData.contact_email
                                        },
                                        {
                                            name: "consumer_number",
                                            type: "string",
                                            value: formData.consumer_number
                                        },
                                        {
                                            name: "account_code",
                                            type: "string",
                                            value: formData.account_code,
                                        },
                                        {
                                            name: "mprn",
                                            type: "string",
                                            value: formData.mprn
                                        },
                                        {
                                            name: "estate",
                                            type: "string",
                                            value: formData.estate
                                        },  
                                    ];

                                    variables.push({
                                        name: "attachments",
                                        type: "string",
                                        value: "true"
                                    });

                                    createTask({
                                        variables: {
                                            task: {
                                                delegationState: "pending",
                                                description: formData.description,
                                                priority: formData.priority,
                                                variables: variables
                                            },
                                            type: getType(props.meterPointsData)
                                        }
                                    }).then((response) => {
                                        setLoading(false);
                                        const taskId = response.data.createTask || response.data.createRemoteTask || response.data.createCamundaTask;
                                        if (!taskId) {
                                            // ErrLogger(formData, "SBMT_QUIRY_ERR", "Task id not returned");
                                            setLoading(false);
                                            ReactDOM.render(<ExpiringAlert color="danger"
                                                                           message={i18n.t('tasks.errorOnCreate')}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                        } else {
                                            taskAtachmentsRef.current.uploadAttachments({
                                                id: taskId,
                                                service: "essence-services"
                                            });
                                            props.onClose();
                                            ReactDOM.render(<ModalDialog title={i18n.t('tasks.successfulCreateTitle')}
                                                                         text={handleSuccessfulCreateTaskMessage()}
                                                                         opened={true}
                                                                         closeLabel={"Close"}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                            // EventLogger(formData, "SBMT_QUIRY_OK");
                                        }
                                    }).catch(
                                        (error) => {
                                            // ErrLogger(formData, "SBMT_QUIRY_ERR", error);
                                            setLoading(false);
                                            ReactDOM.render(<ExpiringAlert color="danger"
                                                                           message={i18n.t('tasks.errorOnCreate')}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                        }
                                    );

                                }}
                            >
                                <TasksAttachments ref={taskAtachmentsRef} uploadImmediately={false}/>
                                <div className="buttons">
                                    <button type="submit" className="btn btn-primary">Add Query</button>
                                    <button type="button" className="btn btn-secondary"
                                            onClick={() => props.onClose()}>Cancel
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Mutation>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default withRouter(CreateTask);
