import React from "react";
import DocumentTypes from "../../components/BillingDocumentTypes/DocumentTypes";

const DocumentTypesView = () => {
  return (
    <div>
      <DocumentTypes />
    </div>
  );
};

export default DocumentTypesView;
