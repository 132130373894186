import React from "react";
import BillingSchemaDetail from "../../components/BillingSchemaDetail/BillingSchemaDetail";

const BillingSchemaDetailsView = () => {
  return (
    <div>
      <BillingSchemaDetail />
    </div>
  );
};

export default BillingSchemaDetailsView;
