import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Query, Mutation } from "react-apollo";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import Form from "react-jsonschema-form";
import i18n from "../../views/Pages/Login/i18n";
import { findAllBillingSchemaParameters } from "../../queries/Queries";
import { saveBillingSchemaMutation } from "../../queries/Queries";
import schema from "../../schemas/billingSchema.schema.json";
import { createUiSchema } from "../../schemas/billingSchema.uischema.js";
import ExpiringAlert from "../../components/ExpiringAlert";
import Loading from "../../components/Loading";

const CreateBillingSchema = ({ history }) => {
  const renderAlert = (message, color) => {
    ReactDOM.render(
      <ExpiringAlert color={color} message={message} />,
      document.getElementById("alert")
    );
  };

  const handleSubmit =
    (saveBillingSchema) =>
    ({ formData }) => {
      const billingSchemaInput = {
        name: formData.name,
        parameters: formData.parameters,
        billingSchemaReference: formData.billingSchemaReference,
        created: new Date().toISOString(),
        createdBy: formData.createdBy,
      };

      saveBillingSchema({
        variables: {
          billingSchema: billingSchemaInput,
        },
      })
        .then((response) => {
          const newId = response.data.saveBillingSchema.id;
          renderAlert(i18n.t("schemas.successfulCreate"), "success");
          history.push(`/schema-document/${newId}`);
        })
        .catch((error) => {
          console.error("Error saving Billing Schema", error);
          renderAlert(error.message, "danger");
        });
    };

  return (
    <Query query={findAllBillingSchemaParameters}>
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <p>Error loading parameters! {error.message}</p>;

        const availableParameters = new Set();
        data.BillingSchema.content.forEach((schema) => {
          Object.keys(schema.parameters || {}).forEach((param) => {
            availableParameters.add(param);
          });
        });

        const defaultParameters = Array.from(availableParameters).reduce(
          (acc, param) => {
            acc[param] = false;
            return acc;
          },
          {}
        );

        const initialData = {
          name: "",
          billingSchemaReference: "",
          parameters: defaultParameters,
          created: "",
          createdBy: "",
        };

        return (
          <Mutation mutation={saveBillingSchemaMutation}>
            {(saveBillingSchema) => (
              <div className='create-billing-schema'>
                <Row className='subheader'>
                  <Col className='col-8'>
                    <h1>Add New Billing Schema Document</h1>
                  </Col>
                  <Col className='col-4'>
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <i className='icon ion-android-home'></i>
                        <Link to='/'>Home</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <Link to='/schema-document'>Billing Schemas</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>Create</BreadcrumbItem>
                    </Breadcrumb>
                  </Col>
                </Row>
                <div className='modal-body'>
                  <Form
                    schema={schema}
                    uiSchema={createUiSchema(defaultParameters, true)}
                    formData={initialData}
                    onSubmit={handleSubmit(saveBillingSchema)}
                  >
                    <div className='buttons'>
                      <button type='submit' className='btn btn-primary'>
                        {i18n.t("schemas.addSchema")}
                      </button>
                      <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => history.push("/schema-document/")}
                      >
                        Cancel
                      </button>
                    </div>
                    {error && renderAlert(error.message, "danger")}
                  </Form>
                </div>
              </div>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default withRouter(CreateBillingSchema);
