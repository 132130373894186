import React from "react";
import DocumentTypesDetail from "../../components/BillingDocumentTypesDetail/DocumentTypeDetail";

const DocumentTypesView = () => {
  return (
    <div>
      <DocumentTypesDetail />
    </div>
  );
};

export default DocumentTypesView;
