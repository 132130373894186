import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Button, Input } from "reactstrap";
import { Query, Mutation } from "react-apollo";
import Loading from "cockpit-ui-dataflows/src/components/Loading";
import {
  getBillingSchemaDetailsQuery,
  assignDocumentTypeToBillingSchemaMutation,
  getAllDocumentTypes,
} from "../../queries/Queries";

const AssignDocumentType = ({ schemaId, onAssign }) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState("");

  return (
    <Query
      query={getAllDocumentTypes}
      variables={{ pageSize: 100, pageNumber: 0 }}
    >
      {({
        loading: documentLoading,
        error: documentError,
        data: documentData,
        refetch: refetchDocumentTypes,
      }) => {
        if (documentLoading) return <Loading />;
        if (documentError)
          return <p>Error fetching document types: {documentError.message}</p>;

        const documentTypes = documentData.findAllDocumentType.content;

        return (
          <Query
            query={getBillingSchemaDetailsQuery}
            variables={{ id: schemaId }}
          >
            {({
              loading: schemaLoading,
              error: schemaError,
              data: schemaData,
              refetch: refetchBillingSchema,
            }) => {
              if (schemaLoading) return <Loading />;
              if (schemaError)
                return (
                  <p>Error fetching billing schema: {schemaError.message}</p>
                );

              const billingSchema = schemaData.findBillingSchema;

              const unassignedDocumentTypes = documentTypes.filter(
                (type) =>
                  !billingSchema.docTypes.some(
                    (assignedType) => assignedType.id === type.id
                  )
              );

              const handleAssign = (
                assignDocumentTypeToBillingSchemaMutation
              ) => {
                if (selectedDocumentType) {
                  assignDocumentTypeToBillingSchemaMutation({
                    variables: {
                      billingSchemaId: schemaId,
                      documentTypeId: selectedDocumentType,
                    },
                    update: (_cache, {}) => {
                      refetchBillingSchema();
                      refetchDocumentTypes();
                      setSelectedDocumentType("");
                      if (onAssign) onAssign();
                    },
                  }).catch((error) =>
                    console.error("Error assigning document type:", error)
                  );
                }
              };

              return (
                <Mutation mutation={assignDocumentTypeToBillingSchemaMutation}>
                  {(
                    assignDocumentTypeToBillingSchemaMutation,
                    { loading: mutationLoading, error: mutationError }
                  ) => (
                    <div>
                      {mutationLoading && <Loading />}
                      {mutationError && <p>Error: {mutationError.message}</p>}
                      <Row>
                        <Col sm='8'>
                          <FormGroup>
                            <Input
                              type='select'
                              value={selectedDocumentType}
                              onChange={(e) =>
                                setSelectedDocumentType(e.target.value)
                              }
                            >
                              <option value='' disabled>
                                Assign a Billing Document Type
                              </option>
                              {unassignedDocumentTypes.map((type) => (
                                <option key={type.id} value={type.id}>
                                  {type.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm='4'>
                          <Button
                            color='primary'
                            onClick={() =>
                              handleAssign(
                                assignDocumentTypeToBillingSchemaMutation
                              )
                            }
                          >
                            Assign
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Mutation>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

export default AssignDocumentType;
