import React from "react";
import moment from "moment";
import { Row } from "reactstrap";

export const READ_STATUS = {
	PENDING_APPROVAL: "Pending Approval",
	NOT_APPROVED: "Not Approved",
	NOT_VALIDATED: "Not Validated",
	VALIDATED: "Validated",
	REJECTED: "Rejected",
};

const READING_SUBMIT_CHANNEL_360 = 'COCKPIT';
const READING_SUBMIT_PROCESS_360 = 'DIO_48';

/**
 * Renders a custom header element with a hidden sort icon.
 * @param {Object} column - The column object.
 * @returns {JSX.Element} - The custom header element.
 */
const hideSortIcon = (column) => {
	return (
		<div className="custom-header">
			{column.text}
			<span className="order" style={{ display: "none" }}>
				<span className="dropdown">
					<span className="caret"></span>
				</span>
				<span className="dropup">
					<span className="caret"></span>
				</span>
			</span>
		</div>
	);
};

/**
 * Thousand separator added to integer values
 */
const setThousandSeparator = (cell) => cell != null ? cell.toLocaleString("en") : "";

/**
 * Returns an array of column configurations for a table.
 * @param {Function} updateStatus - The callback function to handle report action click.
 * @returns {Array} - An array of column configurations.
 */
export const columns = (updateStatus, submitReading) => [
	{
		dataField: "date",
		text: "Date",
		formatter: (cell) => moment(cell).format("DD/MM/YYYY"),
		headerFormatter: hideSortIcon,
		headerStyle: { whiteSpace: "unset" },
		style: { width: "6.8%" },
		sort: true,
	},
	{
		dataField: "site",
		text: "Site",
		headerFormatter: hideSortIcon,
		headerStyle: { whiteSpace: "unset" },
		style: { width: "6.8%" },
		sort: true,
	},
	{
		dataField: "mprn",
		text: "MPRN",
		headerFormatter: hideSortIcon,
		headerStyle: { whiteSpace: "unset" },
		style: { width: "9%" },
		sort: true,
	},
	{
		dataField: "meter_reading",
		text: "New meter reading",
		headerFormatter: hideSortIcon,
		headerStyle: { whiteSpace: "unset" },
		style: { width: "6.8%" },
		formatter: setThousandSeparator,
		sort: true,
	},
	{
		dataField: "uncorrected_rd1_reading",
		text: "Uncorrected RD1 Read",
		headerFormatter: hideSortIcon,
		headerStyle: { whiteSpace: "unset" },
		style: { width: "6.8%" },
		formatter: setThousandSeparator,
		sort: true,
	},
	{
		dataField: "corrected_rd1_reading",
		text: "Corrected RD1 Read",
		headerFormatter: hideSortIcon,
		headerStyle: { whiteSpace: "unset" },
		style: { width: "6.8%" },
		formatter: setThousandSeparator,
		sort: true,
	},
	{
		dataField: "uncorrected_rd1_consumption",
		text: "Uncorrected RD1 Consumption (kWh)",
		headerFormatter: hideSortIcon,
		headerStyle: { whiteSpace: "unset" },
		style: (col, row) => {
			return parseFloat(row.uncorrected_cns_consumption) !==
				parseFloat(row.uncorrected_rd1_consumption)
				? { background: "#f5c0c0", width: "6.8%" }
				: { width: "6.8%" };
		},
		formatter: (cell) => setThousandSeparator(parseInt(cell.toFixed())),
		sort: true,
	},
	{
		dataField: "corrected_rd1_consumption",
		text: "Corrected RD1 Consumption (kWh)",
		headerFormatter: hideSortIcon,
		headerStyle: { whiteSpace: "unset" },
		style: (col, row) => {
			return parseFloat(row.corrected_cns_consumption) !==
				parseFloat(row.corrected_rd1_consumption)
				? { background: "#f5c0c0", width: "6.8%" }
				: { width: "6.8%" };
		},
		formatter: (cell) => setThousandSeparator(parseInt(cell.toFixed())),
		sort: true,
	},
	{
		dataField: "uncorrected_cns_consumption",
		text: "Uncorrected CNS Consumption (kWh)",
		headerFormatter: hideSortIcon,
		headerStyle: { whiteSpace: "unset" },
		style: (col, row) => {
			return parseFloat(row.uncorrected_cns_consumption) !==
				parseFloat(row.uncorrected_rd1_consumption)
				? { background: "#f5c0c0", width: "6.8%" }
				: { width: "6.8%" };
		},
		formatter: (cell) => setThousandSeparator(parseInt(cell.toFixed())),
		sort: true,
	},
	{
		dataField: "corrected_cns_consumption",
		text: "Corrected CNS Consumption (kWh)",
		headerFormatter: hideSortIcon,
		headerStyle: { whiteSpace: "unset" },
		style: (coll, row) => {
			return parseFloat(row.corrected_cns_consumption) !==
				parseFloat(row.corrected_rd1_consumption)
				? { background: "#f5c0c0", width: "6.8%" }
				: { width: "6.8%" };
		},
		formatter: (cell) => setThousandSeparator(parseInt(cell.toFixed())),
		sort: true,
	},
	{
		dataField: "total_power_exported",
		text: "Total Power Exported",
		headerFormatter: hideSortIcon,
		headerStyle: { whiteSpace: "unset" },
		style: { width: "6.8%" },
		formatter: (cell) => setThousandSeparator(parseInt(cell.toFixed())),
		sort: true,
	},
	{
		dataField: "efficiency",
		text: "Efficiency",
		headerFormatter: hideSortIcon,
		headerStyle: { whiteSpace: "unset" },
		style: (cell, row) => {
			const bgColor = row.is_efficiency_in_range ? "#d9fcee" : "#fae1f6";
			const efficiencyMethodColor =
				row.corrected_cns_consumption > 0
					? "#000000"
					: row.corrected_rd1_consumption > 0
					? "#667bb9"
					: bgColor;

			return {
				backgroundColor: bgColor,
				backgroundImage: `-webkit-linear-gradient(60deg, ${bgColor} 50%, ${efficiencyMethodColor} 50.3%)`,
				marginRight: "0.5em",
				width: "6.8%",
				border: "none",
			};
		},
		sort: true,
		sortFunc: (a, b, order, dataField, rowA, rowB) =>
			parseFloat(b) - parseFloat(a),
	},
	{
		dataField: "approve",
		text: "Approve",
		headerFormatter: hideSortIcon,
		headerStyle: { whiteSpace: "unset" },
		style: { width: "6.8%" },
		formatter: (cell, row) => {
			const displaySendBtn =
				parseFloat(row.corrected_rd1_reading) !== 0 ||
				parseFloat(row.uncorrected_rd1_reading) !== 0 ||
				parseFloat(row.meter_reading) !== 0;

			return (
				<Row className="justify-content-around">
					{displaySendBtn &&
						READ_STATUS[row.status] !== READ_STATUS.REJECTED &&
						READ_STATUS[row.status] !== READ_STATUS.NOT_VALIDATED && (
							<button
								onClick={() => {
									const params = {
										meterSerialNumber: row.meter_serial_number,
										meterPointNumber: row.mprn,
										correctorSerialNumber: row.convertor_serial_number,
										readingValue: row.meter_reading,
										readingCorrectedValue: row.corrected_rd1_reading,
										readingUncorrectedValue: row.uncorrected_rd1_reading,
										readingDate: moment(row.date)
											.set({
												// midday in utc
												hour: 14,
												minute: 0,
												second: 0,
											})
											.utc()
											.format(),
										process: READING_SUBMIT_PROCESS_360,
										channel: READING_SUBMIT_CHANNEL_360,
									};

									// Request to submit reading in 360
									submitReading(params).then((response) => {
										if (response && response.data) {
                                            // update status if request was successful
											updateStatus(
												row.mprn,
												row.date,
												"NOT_VALIDATED"
											);
										}
									});
								}}
							>
								<i className={"icon ion-android-send"}></i>
							</button>
						)}
					{READ_STATUS[row.status] !== READ_STATUS.NOT_VALIDATED && (
						<button
							onClick={() => {
								updateStatus(row.mprn, row.date, "REJECTED");
							}}
						>
							<i className={"icon ion-android-close"}></i>
						</button>
					)}
				</Row>
			);
		},
	},
	{
		dataField: "status",
		text: "Status",
		headerFormatter: hideSortIcon,
		headerStyle: { whiteSpace: "unset" },
		style: { width: "9%" },
		formatter: (col) => READ_STATUS[col],
		sort: true,
	},
];
