import React from "react";
import BillingSchemaEdit from "../../components/BillingSchemaEdit/BillingSchemaEdit";

const BillingSchemaEditView = () => {
  return (
    <div>
      <BillingSchemaEdit />
    </div>
  );
};

export default BillingSchemaEditView;
